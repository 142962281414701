<template>
  <div>
    <p class="font-weight-bold">
      PHOTO<span v-if="value.length > 1 || isBonusEligible">S</span>
    </p>
    <p v-if="isBonusEligible">
      Deux photos bligatoires pour pouvoir bénéficier du bonus réparation : une avec le vêtement entièrement visible et
      une à l'endroit de la réparation a effectuer
    </p>
    <v-row>
      <image-preview-item
        v-for="(picture, i) in value"
        :key="picture.url"
        :label="picture.label"
        :url="picture.url"
        @change="p => updatePicture(i, p)"
        @delete="deletePicture(i)"
      />
      <image-preview-item
        v-for="item in placeholders"
        :key="item"
        :label="item"
        @change="addPicture"
      />
    </v-row>
  </div>
</template>

<script>
import ImagePreviewItem from "@/components/interface/ImagePreviewItem.vue";

const LABEL_TO_HAVE_FOR_BONUS = ['Vêtement entier', 'Réparation à effectuer']

export default {
  components: {ImagePreviewItem},
  props: {
    value: {
      default: null,
      type: Array
    },
    isBonusEligible: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    placeholders() {
      if (this.isBonusEligible) {
        const known = this.value.map(v => v.label)
        const filtered = LABEL_TO_HAVE_FOR_BONUS.filter(l => !known.includes(l))

        if (filtered.length > 0) {
          return filtered
        }
      }

      return [undefined]
    }
  },
  methods: {
    addPicture(picture) {
      this.$emit('change', [...this.value, picture])
    },
    updatePicture(index, picture) {
      const items = [...this.value]

      items[index] = picture

      this.$emit('change', items)
    },
    deletePicture(index) {
      const items = [...this.value]

      items.splice(index, 1)

      this.$emit('change', items)
    }
  }
}
</script>
