/*
|---------------------------------------------------------------------
| flux Vuex Mutations
|---------------------------------------------------------------------
|
| Synchronous operations for flux store
|
*/
export default {
    setEnvironment(state, value) {
        state.environment = {...state.environment, ...value}
    },
    setReady(state) {
        state.environment.isReady = true
    },
    setLoaded(state) {
        state.environment.isLoaded = true
    }
}
