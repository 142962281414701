export default {
    showTopbar: true,
    theme: {
        bannerBackgroundColor: '#bed4cd',
        pictoColor: '#bed4cd'
    },
    clotheCategories: ['Manteau/veste', 'Bas', 'Hauts', 'Autre article'],
    clotheTypes: ['MANTEAU', 'DOUDOUNE', 'VESTE/BLOUSON', 'VESTE EN CUIR', 'JEAN', 'AUTRES BAS', 'PULL/GILET', 'SWEAT/HOODIE', 'CHEMISE', 'POLO/T.SHIRT/BLOUSE', 'SALOPETTE/COMBI/ROBE', 'SAC A DOS/SAC SOUPLE', 'LINGERIE/MAILLOT DE BAIN', 'ACCESSOIRES DIVERS'],
    shipments: ['COLISSIMO', 'MONDIAL RELAY', 'WORKSHOPS', 'PARTNERS'],
    redirectUrlAfterPayment: null,
    partnerLogoUrl: null,
    partnerLogoLink: null,
    partnerEngagementSentence: null,
    shipmentProtection: true,
    origin: 'CALCULATEUR',
    isReady: false,
    isLoaded: false
}
