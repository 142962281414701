<template>
  <div>
    <Modal
      v-show="isModalVisible"
      @close="closeModal"

    />
    <v-banner :style="`background-color: ${environment.theme.bannerBackgroundColor}`" class="text-center">
      <p class="h6 m-0">
        Les Réparables sont labellisés Refashion, bénéficiez du Bonus réparation sur les réparations concernées.
      </p>
    </v-banner>
    <v-container class="lighten-5">
      <section id="screen1" :class="step1">
        <v-row>
          <v-col cols="12"><h2 class="mt0 pt10">CATÉGORIES DE VÊTEMENTS</h2>
            <p class="description mtb15 text-center d-none d-sm-block text-center">
              Laissez vous guider et commandez vos réparations en toute simplicité. <br/>
              Pas de panique si vos choix de sélection ne correspondent pas à la réalité, nous reviendrons
              vers vous.
            </p>
            <p class="description mtb15 text-center d-block d-sm-none">
              Laissez vous guider et commandez vos réparations en toute simplicité.
              Pas de panique si vos choix de sélection ne correspondant pas à la réalité, nous reviendrons
              vers vous.
            </p>
          </v-col>
          <v-col :cols="categoryColValues.cols" :md="categoryColValues.md">
            <div
              v-if="clotheCategories.includes('Manteau/veste')"
              class="wrapper_item"
              @click="loadItem('Manteau/veste')"
            >
              <div class="bloc">
                <div class="content">
                  <picto :src="require('@/assets/pictos/manteau.svg')" alt="Manteau" position="top-right"/>
                </div>
              </div>
              <div class="item_label">
                MANTEAUX/VESTES<br/>
                <em>{{ getSubtitleForItem('MANTEAUX/VESTES') }}</em>
              </div>
            </div>
          </v-col>
          <v-col
            v-if="clotheCategories.includes('Bas')"
            :cols="categoryColValues.cols"
            :md="categoryColValues.md"
          >
            <div class="wrapper_item" @click="loadItem('Bas')">
              <div class="bloc">
                <div class="content">
                  <picto :src="require('@/assets/pictos/bas.svg')" alt="Bas" position="bottom"/>
                </div>
              </div>
              <div class="item_label">
                BAS<br/>
                <em>{{ getSubtitleForItem('BAS') }}</em>
              </div>
            </div>
          </v-col>
          <v-col
            v-if="clotheCategories.includes('Hauts')"
            :cols="categoryColValues.cols"
            :md="categoryColValues.md"
          >
            <div class="wrapper_item" @click="loadItem('Hauts')">
              <div class="bloc">
                <div class="content">
                  <picto :src="require('@/assets/pictos/haut.svg')" alt="Haut" position="top-right"/>
                </div>
              </div>
              <div class="item_label">HAUTS<br/>
                <em>{{ getSubtitleForItem('HAUTS') }}</em>
              </div>
            </div>
          </v-col>
          <v-col
            v-if="clotheCategories.includes('Autre article')"
            :cols="categoryColValues.cols"
            :md="categoryColValues.md"
          >
            <div class="wrapper_item" @click="loadItem('Autre article')">
              <div class="bloc">
                <div class="content">
                  <picto :src="require('@/assets/pictos/autre.svg')" alt="Autre" position="right"/>
                </div>
              </div>
              <div class="item_label">
                AUTRES ARTICLES
                <br/>
                <em>{{ getSubtitleForItem('AUTRES ARTICLES') }}</em>
              </div>
            </div>
          </v-col>
        </v-row>
      </section>
      <section v-if="flux.level1" id="screen2" :class="step2">
        <v-row>
          <v-col cols="12">
            <div class="spacer"></div>
            <h2 class="mt10 pt10">TYPES DE VÊTEMENTS</h2>
          </v-col>
          <template v-for="(item, index) in flux.itemsLevel2[flux.idLevel1]">
            <v-col
              v-if="clotheTypes.includes(item.titre)"
              :key="index"
              :cols="getClotheTypeColValues(flux.itemsLevel2[flux.idLevel1]).cols"
              :md="getClotheTypeColValues(flux.itemsLevel2[flux.idLevel1]).md"
              :offset="categoryColValues.offset"
              :offset-sm="categoryColValues.offsetSm"
              class="wrapper_item"
            >
              <div
                :class="{ active: item.titre === flux.level2}"
                @click="loadReparation(index, item.titre,item.display)"
              >
                <div class="bloc">
                  <div class="content">
                    <picto
                      :alt="item.titre" :position="getRepairPictoPosition(index)"
                      :src="require(`@/assets${item.img}`)"
                    />
                  </div>
                </div>
                <div class="item_label">
                  {{ item.titre }}
                  <br/>
                  <em>{{ item.sstitre }}</em>
                </div>
              </div>
            </v-col>
          </template>
          <template v-if="is_double">
            <v-col id="screen_dbl" cols="12">
              <div class="spacer"></div>
              <h2 class="mt10 pt10">DOUBLÉ OU NON DOUBLÉ ?</h2>
            </v-col>
            <v-col cols="12" sm="12">
              <div>
                <p class="description mtb15 text-center">
                  Mon vêtement a une doublure (deuxième épaisseur) : les coutures ne sont pas apparentes
                  sur l’envers.
                  <br/><b>Si oui</b>, cochez la case ci-dessous :
                  <v-checkbox
                    v-model="piece_is_double"
                    class="text-center"
                    label="Mon vêtement est doublé"
                    @click="loadReparation(flux.idLevel2,label2,mode,'chk')"
                  ></v-checkbox>
                </p>
              </div>
            </v-col>
          </template>
        </v-row>
      </section>
      <section v-if="flux.level2" id="screen3" :class="step3">
        <v-row v-if="mode === 'icon'">
          <v-col cols="12">
            <div class="spacer"></div>
            <h2 class="mt10 pt10">TYPES DE RÉPARATIONS</h2>
            <p v-if="flux.level2 === 'SAC A DOS/SAC SOUPLE'">
              <v-icon>mdi-information-slab-circle-outline</v-icon>
              Nous ne traitons pas les articles de maroquinerie
            </p>
          </v-col>
          <v-col v-for="(reparations, index) in typeReparations" :key="index" cols="6" sm="4">
            <div v-if="typeof reparations.filter !== 'undefined' && reparations.filter === 'is_dbl'"
                 class="wrapper_item" @click="loadRepair(reparations.name)">
              <div class="bloc">
                <div class="content">
                  <img alt="ok" src="https://www.lesreparables.fr/cv2/clothetype/new/1.png"/>
                </div>
              </div>
              <div class="item_label">
                {{ reparations.titre }}
                <br/>
                <em>{{ reparations.sstitre }}</em>
              </div>
            </div>
            <div v-if="typeof reparations.filter === 'undefined'" :class="{ active: reparations.name === flux.level3}"
                 class="wrapper_item"
                 @click="loadRepair(reparations.name)">

              <div class="bloc">
                <div class="content">
                  <picto
                    :alt="reparations.titre" :position="getRepairPictoPosition(index)"
                    :src="require(`@/assets${reparations.img}`)"
                  />
                </div>
              </div>
              <div class="item_label">
                {{ reparations.titre }}
                <br/>
                <em>{{ reparations.sstitre }}</em>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="mode === 'form'">
          <v-col cols="12">
            <div class="wrapper_item">
              <Personnalisee @evenement="evenement"/>
            </div>
          </v-col>
        </v-row>
      </section>
      <section v-if="flux.componentName" id="screen4" :class="step4">
        <v-row>
          <v-col cols="12">
            <div class="spacer"></div>
            <v-container class="lighten-5">
              <component
                v-bind:is="flux.componentName"
                @edit="editRepair"
                @evenement="evenement"
                @showmodal="showModal"
              />
            </v-container>
          </v-col>
        </v-row>
      </section>
    </v-container>
  </div>
</template>

<script>
import Modal from './Modal.vue';
import jsondata from '../assets/data/data.json'
import reparationdata from '../assets/data/type_reparations.json'
import Personnalisee from '../components/reparations/personnalisee.vue'
import {mapMutations, mapState} from 'vuex'
import Picto from "@/components/shared/Picto.vue";

export default {
  name: 'Calculateur',
  props: {
    msg: String
  },
  data() {
    return {
      step1: 'inprogress',
      step2: '',
      step3: '',
      step4: '',
      choix1: null,
      choix2: null,
      isModalVisible: false,
      items: [],
      typeReparations: [],
      is_double: false,
      piece_is_doubleT: false,
      mode: 'icon',
      label2: null
    }
  },
  computed: {
    ...mapState('cart-app', ['cards', 'products']),
    ...mapState('environment-app', ['environment']),
    ...mapState('flux-app', ['flux']),
    piece_is_double: {
      // getter
      get() {
        return this.piece_is_doubleT
      },
      // setter
      set(newValue) {
        // Note: we are using destructuring assignment syntax here.
        this.piece_is_doubleT = newValue
      }
    },
    isJott() {
      return this.flux.brand === 'jott'
    },
    clotheCategories() {
      if (this.isJott) {
        return ['Manteau/veste']
      }

      return this.environment.clotheCategories
    },
    clotheTypes() {
      if (this.isJott) {
        return ['DOUDOUNE']
      }

      return this.environment.clotheTypes
    },
    categoryColValues() {
      const md = 12 / this.clotheCategories.length
      return {
        cols: md < 6 ? 6 : md,
        md
      }
    }
  },
  created() {
    this.setItemsLevel2(jsondata)
    this.setItemsLevel3(reparationdata)
  },
  mounted() {
    if (this.clotheCategories.length === 1) {
      this.loadItem(this.clotheCategories[0], true)
    }

    if (this.clotheTypes.length === 1) {
      this.$nextTick(() => {
        this.loadReparation(1, this.clotheTypes[0], 'icon', null, true)
      })
    }
  },
  methods: {
    ...mapMutations('flux-app', ['setLevel1', 'setLevel2', 'setLevel3', 'setDouble', 'setIdLevel1', 'setItemsLevel2', 'setItemsLevel3', 'setIdLevel2', 'setSelectedItem', 'setComponentName', 'setVerrou', 'setindexEditProduit', 'setindexEditReparation', 'setcptProduct']),
    editRepair(indexes) {
      this.$emit('edit', indexes)
    },
    evenement(value) {
      if (value == 'scroll3') {
        this.$scrollTo('#screen3')
      }
      if (value == 'scroll1') {
        this.$scrollTo(this.isJott ? '#screen2' : '#screen1')
      }
    },
    //Type vetement bis
    loadItem(label, immediate = false) {
      this.setcptProduct(this.flux.cptProduct + 1)
      this.setVerrou(false)
      this.setindexEditProduit(null)
      this.setindexEditReparation(null)
      this.setComponentName()
      this.setIdLevel1(this.getClotheCategoryId(label))
      this.setLevel1(label)
      this.flux.level2 = null
      this.flux.level3 = null
      this.is_double = false

      this.piece_is_double = false
      this.typeReparations = []
      this.step2 = 'inprogress'
      this.$nextTick(() => this.$scrollTo('#screen2', immediate ? 0 : 500))
      this.setComponentName(null)
    },
    loadReparation(id, label = null, display = 'icon', context = null, immediate = false) {
      this.is_double = ['SALOPETTE/COMBI/ROBE', 'SAC A DOS/SAC SOUPLE', 'ACCESSOIRES DIVERS'].includes(label);

      this.typeReparations = []
      this.setComponentName(null)
      if (display == 'icon') {
        this.mode = display
        this.setIdLevel2(id)
        this.label2 = label
        this.setLevel2(label)
        let tmp_reparations = this.flux.itemsLevel3[this.flux.idLevel1]?.[this.flux.idLevel2] || []

        for (var i = 0; i < tmp_reparations.length; i++) {
          if (
            (this.piece_is_double === true || typeof tmp_reparations[i].filter === 'undefined') &&
            tmp_reparations[i].name !== 'personnalisee'
          ) {
            this.typeReparations.push(tmp_reparations[i])
          }
        }
      } else if (display == 'form') {
        this.mode = display
        this.setIdLevel2(id)
        this.label2 = label
        this.setLevel2(label)
      }

      this.setDouble(this.piece_is_double)
      if (this.mode === 'form') {
        this.step3 = 'inprogress'
        this.$nextTick(() => this.$scrollTo('#screen3', immediate ? 0 : 500))
      } else if (this.is_double && context !== 'chk') {
        this.$nextTick(() => this.$scrollTo('#screen_dbl', immediate ? 0 : 500))
      } else if (context === 'chk' || this.is_double === false) {
        this.setDouble(this.piece_is_double)
        this.step3 = 'inprogress'
        this.$nextTick(() => this.$scrollTo('#screen3', immediate ? 0 : 500))
      }

    },
    loadRepair(name) {
      this.setComponentName(null)
      this.setLevel3(name)
      this.setComponentName(this.flux.level3)
      this.step4 = 'inprogress'
      this.$nextTick(() => this.$scrollTo('#screen4'))
    },
    showModal(value) {
      this.$emit('showmodal', value)
    },
    closeModal() {
      this.isModalVisible = false;
    },
    getClotheCategoryId(category) {
      if (category === 'Manteau/veste') {
        return 0
      }

      if (category === 'Bas') {
        return 1
      }

      if (category === 'Hauts') {
        return 2
      }

      return 3
    },
    getRepairPictoPosition(index) {
      return ['top-left', 'bottom-right', 'top-right', 'bottom', 'left', 'top-right', 'right'][index]
    },
    getClotheTypeColValues(types) {
      const typesCount = types.reduce((acc, type) => {
        if (this.clotheTypes.includes(type.titre)) {
          acc.push(type)
        }

        return acc
      }, []).length


      const md = 12 / typesCount
      return {
        cols: md < 6 ? 6 : md,
        md
      }
    },
    getSubtitleForItem(item) {
      const subtitles = []

      if (item === 'MANTEAUX/VESTES') {
        if (this.environment.clotheTypes.includes('MANTEAU')) {
          subtitles.push('manteau')
        }
        if (this.environment.clotheTypes.includes('DOUDOUNE')) {
          subtitles.push('doudoune')
        }
        if (this.environment.clotheTypes.includes('VESTE/BLOUSON')) {
          subtitles.push('blouson, veste')
        }
      }

      if (item === 'BAS') {
        if (this.environment.clotheTypes.includes('JEAN')) {
          subtitles.push('jean')
        }
        if (this.environment.clotheTypes.includes('AUTRES BAS')) {
          subtitles.push('pantalon, short, jupe, jogging')
        }
      }

      if (item === 'HAUTS') {
        if (this.environment.clotheTypes.includes('PULL/GILET')) {
          subtitles.push('pull')
        }
        if (this.environment.clotheTypes.includes('SWEAT/HOODIE')) {
          subtitles.push('sweat')
        }
        if (this.environment.clotheTypes.includes('CHEMISE')) {
          subtitles.push('chemise')
        }
        if (this.environment.clotheTypes.includes('POLO/T.SHIRT/BLOUSE')) {
          subtitles.push('polo, t-shirt, blouse')
        }
      }

      if (item === 'AUTRES ARTICLES') {
        if (this.environment.clotheTypes.includes('SALOPETTE/COMBI/ROBE')) {
          subtitles.push('robe')
        }
        if (this.environment.clotheTypes.includes('LINGERIE/MAILLOT DE BAIN')) {
          subtitles.push('lingerie, maillot de bain')
        }
        if (this.environment.clotheTypes.includes('SAC A DOS/SAC SOUPLE')) {
          subtitles.push('sac à dos')
        }
        if (this.environment.clotheTypes.includes('ACCESSOIRES DIVERS')) {
          subtitles.push('accessoires divers')
        }
      }

      const subtitle = subtitles.join(', ')

      return `${subtitle.charAt(0).toUpperCase()}${subtitle.slice(1)}...`
    }
  },
  components: {
    Picto,
    Modal,
    Personnalisee,
    'couture-decousue': () => import('../components/reparations/couture.vue'),
    'pression': () => import('../components/reparations/pression.vue'),
    'bouton': () => import('../components/reparations/bouton.vue'),
    'zip': () => import('../components/reparations/zip.vue'),
    'trou-accroc-dechirure': () => import('../components/reparations/trou.vue'),
    'trou-jean': () => import('./reparations/trou_jean.vue'),
    'sangle': () => import('../components/reparations/sangle.vue'),
    'attache-regleur-bouton': () => import('../components/reparations/attache-regleur-bouton.vue'),
    'baleine-armature': () => import('../components/reparations/baleine-armature.vue'),
    bretelles: () => import('../components/reparations/bretelles.vue'),
  }
}
</script>

<style scoped>
#screen1, #screen2, #screen3, #screen4 {
  min-height: calc(100vh + 10px);
}

#screen2, #screen3, #screen4 {
  padding-top: 50px;
}

@media (min-width: 768px) {
  #screen1, #screen2, #screen3, #screen4 {
    min-height: 100vh;
    padding-top: 100px;
  }

  #screen1 {
    min-height: calc(100vh - 64px - 50px);
  }
}
</style>
