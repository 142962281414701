<template>
  <v-col cols="6" md="4">
    <div :class="{skeleton: !url}" class="container" @click="!url && $refs.file && $refs.file.click()">
      <v-progress-circular v-if="isLoading" indeterminate/>
      <img v-else-if="url" :src="url" alt="Votre photo"/>
      <template v-else>
        <v-icon>mdi-plus</v-icon>
        {{ label }}
      </template>
    </div>
    <div v-if="url" class="d-flex justify-content-between actions">
      <p @click="$refs.file && $refs.file.click()">Modifier</p>
      <p @click="$emit('delete')">Supprimer</p>
    </div>
    <input
      id="file"
      ref="file"
      accept="image/*"
      multiple
      placeholder="Sélectionnez votre photo"
      type="file"
      v-on:change="handleFileUpload()"
    />
  </v-col>
</template>

<script>
import config from "../../../config";
import axios from "@/axios";

const DEFAULT_LABEL = 'Nouvelle photo'

export default {
  name: "ImagePreviewUnit",
  props: {
    url: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: DEFAULT_LABEL
    }
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    visibleLabel() {
      if (this.label === DEFAULT_LABEL) {
        return null
      }

      return this.label
    }
  },
  methods: {
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      this.submitFile();
    },
    submitFile() {
      this.error = null
      this.isLoading = true;
      let formData = new FormData();
      formData.append('file', this.file);

      if (this.visibleLabel) {
        formData.append('label', this.visibleLabel);
      }

      axios.post(`${config.apiHost}/envoi-photo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          this.$emit('change', {
            id: response.data.id,
            url: response.data.file,
            label: this.visibleLabel
          })
        })
        .catch(() => {
          this.error = 'Une erreur est survenue, votre photo n\'a pas pu être chargée.'
        })
        .finally(() => this.isLoading = false);
    }
  }
}
</script>

<style scoped>
.container {
  height: 150px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.skeleton {
  border: dashed 1px;
  cursor: pointer;
}

.container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.actions {
  padding: 0 10px;
}

.actions p {
  cursor: pointer;
  margin: 10px 0;
  font-size: 0.9em;
}

input[type=file] {
  display: none;
}
</style>
