<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col class="d-flex align-items-center justify-center mb-5" cols="12">
          <p>
            La réparation simple peut être un patch, une couture simple ou encore un empiècement.<br/>
            Visible mais
            discrète, cette réparation est plus abordable que la cloison. À réception, nous choisissons la
            réparation la plus adaptée à votre déchirure.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-1" cols="12" md="6">
          <img src="https://www.lesreparables.fr/cv2/clothetype/modal/reparation-doudoune-1.png"/>
        </v-col>
        <v-col class="pa-1" cols="12" md="6">
          <img src="https://www.lesreparables.fr/cv2/clothetype/modal/reparation-doudoune-2.png"/>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ZipEtancheModalContent"
}
</script>

<style scoped>
img {
  max-width: unset !important;
}
</style>
