<template>
  <v-app>
    <div v-if="!environment.isReady" id="spinner-container">
      <big-spinner/>
    </div>
    <template v-else>
      <Modal
        v-show="isModalVisible"
        :constat="flux.constat"
        :type="type"
        @close="closeCart"
        @delete="deleteRepair"
        @edit="editRepair"
        @evenement="evenement"
      />
      <v-app-bar
        v-if="environment.showTopbar"
        app
        color="primary"
        dark
      >
        <div class="d-flex align-center">
          <v-img
            :class="`shrink mr-2 ${regularLogoClasses}`"
            alt="Les Réparables"
            contain
            max-height="34"
            src="@/assets/logo.png"
            transition="scale-transition"
            width="200"
          />
          <v-img
            :class="`shrink mr-1 ${smallLogoClasses}`"
            alt="Les Réparables"
            contain
            max-height="34"
            src="@/assets/logo-small.png"
            transition="scale-transition"
            width="34"
          />
          <template v-if="partnerLogoUrl">
            <span class="mx-1" style="font-size: 20px">X</span>
            <a :href="environment.partnerLogoLink"
               :style="`cursor: ${environment.partnerLogoLink ? 'pointer' : 'unset'};`"
               target="_blank">
              <v-img
                :height="flux.brand === 'jott' ? '52px' : ''"
                :src="partnerLogoUrl"
                :style="flux.brand === 'jott' ? 'border-radius: 100%' : ''"
                :width="flux.brand === 'jott' ? '50px' : ''"
                class="ml-5"
                max-height="52px"
                max-width="145px"
              />
            </a>
          </template>
        </div>

        <v-spacer></v-spacer>
        <div v-if="$route.name === 'Home'">
          <v-badge
            v-if="products.length > 0"
            :content="(products.length)"
            bordered
            color="error"
            icon="mdi-lock"
            left
            overlap
          >
            <v-btn
              :small="$vuetify.breakpoint.xsOnly && !partnerLogoUrl"
              :x-small="$vuetify.breakpoint.xsOnly && partnerLogoUrl"
              text
              @click="showModal('cart')"
            >
              <v-icon dark>
                mdi-cart-variant
              </v-icon>
            </v-btn>
          </v-badge>
          <v-btn
            v-else
            :small="$vuetify.breakpoint.xsOnly && !partnerLogoUrl"
            :x-small="$vuetify.breakpoint.xsOnly && partnerLogoUrl"
            text
            @click="showModal('cart')"
          >
            <v-icon dark>
              mdi-cart-variant
            </v-icon>
          </v-btn>
          <a :href="accountUrl" class="ml-2" target="_blank">
            <v-btn
              :small="$vuetify.breakpoint.xsOnly && !partnerLogoUrl"
              :x-small="$vuetify.breakpoint.xsOnly && partnerLogoUrl"
              text
            >
              <v-icon dark>
                mdi-account-circle
              </v-icon>
            </v-btn>
          </a>
        </div>
      </v-app-bar>

      <v-main>
        <router-view :key="$route.fullPath" @edit="editRepair" @showmodal="showModal"/>
      </v-main>

      <Footer/>
    </template>
  </v-app>
</template>

<script>
import Modal from './components/Modal.vue';
import {mapMutations, mapState} from 'vuex'
import "leaflet/dist/leaflet.css"
import BigSpinner from "@/components/shared/BigSpinner.vue";
import Footer from "@/components/Footer.vue";
import config from "@/../config"

export default {
  name: 'App',

  components: {
    Footer,
    BigSpinner,
    Modal
  },
  computed: {
    ...mapState('cart-app', ['cards', 'products']),
    ...mapState('environment-app', ['environment']),
    ...mapState('flux-app', ['flux']),
    partnerLogoUrl() {
      if (this.flux.brand === 'jott') {
        return require('@/assets/jott.png')
      }

      return this.environment.partnerLogoUrl
    },
    regularLogoClasses() {
      return this.partnerLogoUrl ? 'd-none d-sm-block' : 'd-block'
    },
    smallLogoClasses() {
      return this.partnerLogoUrl ? 'd-block d-sm-none mr-7' : 'd-none'
    }
  },
  data() {
    return {
      isModalVisible: false,
      type: 'cart',
      setupInitialized: false,
      accountUrl: config.accountUrl
    }
  },
  methods: {
    ...mapMutations('flux-app', ['setcptProduct', 'setcptReparation', 'setindexEditProduit', 'setindexEditReparation', 'setLevel1', 'setLevel2', 'setLevel3', 'setDouble', 'setVerrou', 'setIdLevel1', 'setActionId', 'setComponentName', 'setActiveTab', 'setBrand']),
    ...mapMutations('cart-app', ['addProduct', 'getTotalCart', 'afterDelete']),
    ...mapMutations('environment-app', ['setEnvironment', 'setReady']),
    showModal(value) {
      if (value === undefined) {
        this.type = this.flux.level2
      } else {
        this.type = value
      }

      this.isModalVisible = true;
    },
    closeCart() {
      this.isModalVisible = false;
    },
    deleteRepair(indexes) {
      let index = this.products[indexes[0]].reparations.findIndex((obj => obj.id == (indexes[1] + 1)));
      this.products[indexes[0]].reparations.splice(index, 1);
      //this.setcptReparation((this.flux.cptReparation-1))
      if (this.products[indexes[0]].reparations.length === 0) {
        this.products.splice(indexes[0], 1)
        this.setcptProduct(this.products.length)
      }
      this.getTotalCart(this.products)
      this.afterDelete()
    },
    editRepair(indexes) {
      this.setVerrou(false)
      this.setindexEditProduit(indexes[0]);
      this.setindexEditReparation(indexes[1])
      this.setLevel1(this.products[this.flux.indexEditProduit]['level1'])
      this.setLevel2(this.products[this.flux.indexEditProduit]['level2'])
      this.setLevel3(this.products[this.flux.indexEditProduit]['level3'])
      this.setComponentName(this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['componentName'])
      this.setDouble(this.products[this.flux.indexEditProduit]['isDouble'])
      this.setIdLevel1(this.products[this.flux.indexEditProduit]['idLevel1'])
      if (this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['activeTab'] === undefined) {
        this.setActiveTab(null)
      } else {
        this.setActiveTab(this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['activeTab'])
      }
      this.setActionId(Math.random())
    },
    evenement(value) {
      if (value == 'scroll3') {
        this.$scrollTo('#screen3')
      }
      if (value == 'scroll4') {
        this.$scrollTo('#screen4')
      }
      if (value == 'scroll1') {
        this.$scrollTo('#screen1')
      }
      if (value == 'cart') {
        this.showModal()
      }
    }
  },
  watch: {
    '$route.query': {
      deep: true,
      immediate: true,
      handler: function (value) {
        this.setBrand(value?.brand || null)

        if (value?.origin) {
          this.setEnvironment({origin: value.origin})
          this.setReady()
        }
      }
    },
    isModalVisible(visible) {
      document.documentElement.style.overflow = visible ? 'hidden' : 'scroll'
    }
  },
  mounted() {
    const isInIframe = window.self !== window.top

    if (!isInIframe) {
      this.setReady()
    }

    if (isInIframe) {
      window.addEventListener('message', event => {
        if (event?.data?.event === 'setup') {
          this.setEnvironment(event.data.configuration)
          this.setReady()
        }

        if (event?.data?.event === 'open-basket') {
          this.showModal('cart');
        }
      })
    }
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Jost:100,200,300,400,500,600,700,800,900");

#spinner-container {
  width: 100%;
  height: 100%;
  display: flex;
}

#input-66 {
  margin-top: 5px !important;
  font-size: 11px;
}

.spacer {
  padding: 15px 0 0 0;
}

.v-expansion-panel {
  border-bottom: 1px solid #ccc !important;
  border-radius: 0 !important;
}

h3 {
  margin: 40px 0 0;
}

h4 {
  font-size: 1.2rem !important;
}

.active {
  position: relative;
  text-align: center;
  padding: 20px 20px 20px !important;
  margin-bottom: 10px !important;
  vertical-align: middle;
  transition: all 0.3s;
  border-radius: 6px;
  border: 1px solid transparent !important;
  width: 100%;
  /*max-width: 250px;*/
  margin-right: 10% !important;
  overflow: visible;
  background-color: #eee;
}

.v-application h2 {
  font-family: 'Jost', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 400;
  font-size: 1.4rem !important;
  text-transform: uppercase;
  letter-spacing: 2.9px;
  margin-bottom: 30px;
  text-align: center;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #BED4CD;
}

div.price {
  text-align: center;
  font-size: 1.3rem;
}

section.inprogress {
  min-height: calc(100vh - 64px - 50px);
}

.fin {
  height: 100vh;
  border-bottom: none !important
}

.screen {
  padding-top: 80px;
}

.item {
  width: 98%;
  vertical-align: middle;
  border: 1px solid #000000;
  margin: 0 5px 5px 0;
  padding: 20px 20px 10px !important;
}

.item_label {
  height: 40px;
  text-align: center;
  font-size: 12px;
}

.item_label em {
  font-size: 12px;
}

.bloc {
  width: 100%;
  vertical-align: middle;
  margin: auto;
}

.bloc .content {
  width: 100%;
  height: 100%;
  text-align: center;
}

.v-stepper {
  border-radius: 0 !important;
  box-shadow: none !important;
}

.content img {
  width: 150px;
  height: 150px;
}

.description {
  padding: 5px;
}

.mtb15 {
  margin: 15px 0;
}

.mt10 {
  margin-top: 10px;
}

.v-label {
  color: #000000 !important
}

.v-input__slot {
  margin-bottom: 0 !important;
}

thead td {
  font-weight: bold;
  text-align: left;
}

tr td {
  padding-bottom: 10px;
}

.btn-1 {
  background-color: #000 !important;
  color: #fff !important;
  border-radius: 0 !important;
}

.btn-2 {
  border: 1px solid #000 !important;
  background-color: #fff !important;
  color: #000 !important;
  border-radius: 0 !important;
}

.btn-size {
  min-width: 80px;
}

.btn-full {
  width: 250px !important;
  max-width: 100%;
}

.btn-invisible {
  background-color: transparent !important;
  box-shadow: inherit;
  min-width: inherit !important;
  height: inherit !important;
  padding: inherit !important;
}

.pricerepa {
  text-align: center;
  font-weight: 400;
  font-size: 3rem;
  padding: 70px 0;
}

.wrapper_item {
  padding: 5px 0;
}

.activesection::after {
  content: "\2714" !important;
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
  pointer-events: none;
  opacity: 1;
  transition: all .3s;
  text-align: center;
  line-height: 30px;
  color: #ffffff !important;
  display: block;
  position: absolute;
  left: 50%;
  margin: -20px 0 0 -20px;
  top: auto;
  bottom: -16px;
  background-color: #76A185;
}

.v-input--radio-group__input label {
  font-size: 1rem !important;
  padding-top: 8px !important;
}

ul.ul_normal {
  list-style-type: disc;
  padding-left: 0 !important
}

ul.ul_normal li {
  display: block;
}

ul.ul_normal li::before {
  content: "•";
  color: #000;
  display: inline-block;
  width: 0.4em;
  margin-left: 0;
  font-size: 4rem;
  line-height: 2rem;
  vertical-align: middle;
  margin-top: 0.2rem;
}

.mondial-relay-widget {
  font-family: 'Jost', Helvetica, Arial, Lucida, sans-serif !important;
  border: none !important;
  max-width: none !important;
}

.mondial-relay-header__title {
  display: none;
}

.mondial-relay-selector__dropdown li {
  width: 100%;
  margin: 0;
}

.mondial-relay-selector__input__flag img,
.mondial-relay-selector__dropdown img {
  width: 30px;
  height: 20px;
}

.mondial-relay-form__search img {
  width: 20px;
}

.mondial-relay-widget-hide-desktop {
  height: 33px;
  display: flex;
  margin: 0 10px
}

.mondial-relay-tab {
  border-color: #000 !important;
}

.mondial-relay-tab.mondial-relay-widget-hide-desktop button {
  height: 100%;
  width: 50%;
  padding: 0 !important;
  background-color: #fff;
  color: #000;
}

.mondial-relay-tab.mondial-relay-widget-hide-desktop button.active {
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 0;
  background-color: #000;
  color: #fff;
}

/* Disable auto zoom in form */
input[type="text"], textarea {
  font-size: 16px;
}

.v-text-field__details {
  margin-top: 3px
}

@media (max-width: 576px) {
  .mondial-relay-header .mondial-relay-header__column--left {
    display: none;
  }

  button.mondial-relay-form__search {
    width: 100%;
    border: solid 1px black;
    padding: 5px;
    margin: 8px 0;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 975px !important;
  }

  .modal-body .wrapper {
    max-width: 975px !important;
    margin: auto;
  }
}

/* 2340x1080 pixels at 476ppi */
@media only screen
and (min-width: 375px) {
  .v-window-item {
    min-height: 100vh;
  }
}
</style>
