<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col class="d-flex align-items-center justify-center mb-5" cols="12">
          <p>
            La cloison abîmée est recouverte de tissu.<br/>
            Cette réparation premium est invisible lorsque nous avons le même tissu.<br/>
            Sinon, elle reste discrète et en harmonie avec votre vêtement.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-1" cols="12" md="6">
          <img src="https://www.lesreparables.fr/cv2/clothetype/modal/empiecement-doudoune-1.png"/>
        </v-col>
        <v-col class="pa-1" cols="12" md="6">
          <img src="https://www.lesreparables.fr/cv2/clothetype/modal/empiecement-doudoune-2.png"/>
        </v-col>
        <v-col class="pa-1" cols="12" md="6">
          <img src="https://www.lesreparables.fr/cv2/clothetype/modal/empiecement-doudoune-3.png"/>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ZipEtancheModalContent"
}
</script>

<style scoped>
img {
  max-width: unset !important;
}
</style>
