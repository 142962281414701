<template>
  <transition name="modal-fade">
    <div class="mymodal-backdrop">
      <div class="mymodal">
        <header class="modal-header">
          <slot name="header">
            <span/>
            <h2 v-if="title">{{ title }}</h2>
            <v-btn @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </slot>
        </header>

        <section v-if="useComponent" class="modal-body">
          <slot name="body">
            <component :is="componentName" :type="$props.type"/>
          </slot>
        </section>

        <section v-else-if="$props.type=='cart' || $props.constat=='cart'" class="modal-body">
          <slot name="body">
            <v-row>
              <v-col cols="12" md="8">
                <div style="padding:10px">
                  <div v-if="products.length>0">
                    <div v-for="(product, index) in products" :key="index" class="cproduct">
                      <h4>Article {{ (index + 1) }} -
                        {{ product.famille }}/{{ product.name }}</h4>
                      <div v-if="product.reparations">
                        <table id="cart_tab">
                          <thead>
                          <td>Réparation</td>
                          <td>Prix TTC</td>
                          <td></td>
                          </thead>
                          <tr v-for="(reparation, indexr) in product.reparations"
                              :key="indexr">
                            <td width="50%">
                              {{ reparation.type }}
                              <br/>
                              <div v-html="reparation.description"></div>
                            </td>
                            <td>
                              {{ formatPrice(reparation.price - (reparation.bonus || 0)) }}
                            </td>
                            <td>
                              <!--                                                            <a @click="gotoRepair(index,indexr)">Modifier</a> /-->
                              <a @click="deleteProduct(index,indexr)">Supprimer</a>
                            </td>
                          </tr>
                          <tr>
                            <td align="right" class="soustotal" colspan="3"></td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <p>Vous n'avez pas d'articles dans votre panier.</p>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <div v-if="products.length>0" class="recap">
                  <h2 class="hmodal">RÉSUMÉ DE LA COMMANDE</h2>
                  <br/>
                  <template v-if="cards.totalBonus">
                    <div class="mb-5"
                         style="display: flex;
                                             align-items: center; justify-content: center;"
                    >
                      <img id="bonus" alt="Logo bonus" class="mr-5" src="@/assets/bonus.png"/>
                      <div>
                        <div class="text-center">
                          Total :
                          <span v-html="formatPrice(cards.totalTTC + cards.totalBonus)"/>
                        </div>
                        <div class="text-center">
                          Bonus réparation : -<span v-html="formatPrice(cards.totalBonus)"/>
                        </div>
                      </div>
                    </div>

                    <div class="text-center" style="font-size: 1.2rem">
                      Total des réparations : {{ formatPrice(cards.totalTTC) }} TTC
                    </div>
                  </template>
                  <div v-else class="text-center">
                    TOTAL : {{ formatPrice(cards.totalTTC) }} TTC
                  </div>
                  <br/><br/>
                  <div class="action text-center">
                    <router-link class="btn btn-1 btn-full" to="/step/1" @click.native="close">JE
                      PASSE COMMANDE
                    </router-link>
                    <br/><br/>
                    <div class="btn btn-2 btn-full" @click="other">J'AI UN AUTRE ARTICLE À REPARER
                    </div>
                  </div>
                </div>
                <div v-else class="recap">
                  <h2 class="hmodal">RÉSUMÉ DE LA COMMANDE <br/></h2>
                  <br/>
                  <div class="text-center">
                    <b>TOTAL : 0 € TTC</b>
                  </div>
                  <br/><br/>
                  <div class="action text-center">
                    <br/><br/>
                    <div class="btn btn-2 btn-full" @click="close">FERMER LA FENÊTRE</div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </slot>
        </section>

        <section v-else-if="$props.type=='MANTEAU'" class="modal-body">
          <div class="wrapper">
            <slot v-if="$props.constat=='couture-decousue'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">ALORS DECOUSU OU DECHIRE ?</h2>
                  <br/><br/><br/>
                  <p>
                    <b>Couture décousue</b><br/>
                    <br/>
                    Lorsque la couture est décousue, la matière est en bon état. Vous êtes donc au
                    bon endroit !
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/manteau-decousu.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/manteau-decousu-2.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/manteau-decousu-3.png"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <br/><br/><br/>
                  <p>
                    <b>Couture déchirée, trouée, accroc ?</b><br/>
                    <br/>
                    Lorsque la matière est déchirée, il s'agit bien d'un accroc, d'un trou ou d'une
                    couture déchirée.
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/manteau-dechire.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/manteau-dechire-2.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/manteau-dechire-3.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='empiecement'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">EMPIÈCEMENT</h2>
                  <br/><br/><br/>
                  <p>
                    Après avoir sélectionné ensemble la matière première, nous réalisons un
                    empiècement pour réparer votre vêtement.
                    <br/><br/>
                    Le but, rendre la réparation le plus invisible possible en respectant le style
                    de votre vêtement.
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/empiecement.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/empiecement-2.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/empiecement-3.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='patch'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">Patch / Écusson</h2>
                  <br/><br/><br/>
                  <p>
                    Voici quelques exemples de patch que l'on peut vous proposer.
                    <br/><br/>
                    * en fonction des stocks disponibles
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/patch.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/patch-2.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='coudiere'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">COUDIÈRES / GENOUILLÈRES</h2>
                  <br/><br/><br/>
                  <p>
                    Voici quelques exemples de coudières que l'on peut vous proposer.
                    <br/><br/>
                    * en fonction des stocks disponibles
                  </p>
                </v-col>
                <v-col class="pa-1" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/patch-3.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='reparation-simple'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">TOUT SAVOIR SUR <br/>LA COUTURE SIMPLE</h2>
                  <br/><br/><br/>
                  <p>
                    La couture simple permet de rapprocher les matières par un point main ou une
                    pince.
                    <br/><br/>
                    Le résultat n'est pas invisible mais reste discret et abordable.
                    <br/><br/>
                    Quelques exemples : <br/>
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img
                    src="https://www.lesreparables.fr/cv2/clothetype/modal/couture-simple-haut.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img
                    src="https://www.lesreparables.fr/cv2/clothetype/modal/couture-simple-haut-2.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img
                    src="https://www.lesreparables.fr/cv2/clothetype/modal/couture-simple-haut-3.png"/>
                </v-col>
              </v-row>
            </slot>
          </div>
        </section>

        <section v-else-if="$props.type=='DOUDOUNE'" class="modal-body">
          <div class="wrapper">
            <slot v-if="$props.constat=='couture-decousue'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">ALORS DECOUSU OU DECHIRE ?</h2>
                  <br/><br/><br/>
                  <p>
                    <b>Couture décousue</b><br/>
                    <br/>
                    Lorsque la couture est décousue, la matière est en bon état. Vous êtes donc au
                    bon endroit !
                  </p>
                </v-col>
                <v-col class="pa-1" cols="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/manteau-decousu.png"/>
                </v-col>
                <v-col class="pa-1" cols="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/manteau-decousu-2.png"/>
                </v-col>
                <v-col class="pa-1" cols="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/manteau-decousu-3.png"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <br/><br/><br/>
                  <p>
                    <b>Couture déchirée, trouée, accroc ?</b><br/>
                    <br/>
                    Lorsque la matière est déchirée, il s'agit bien d'un accroc, d'un trou ou d'une
                    couture déchirée.
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/manteau-dechire.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/manteau-dechire-2.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/manteau-dechire-3.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='empiecement'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">RÉPARATION D'UNE CLOISON</h2>
                  <br/><br/><br/>
                  <p>
                    La réparation d'un trou par une cloison consiste à recouvrir le boudin de la
                    doudoune abimé par une matière similaire ou identique.
                    <br/><br/>
                    Nous pouvons utiliser le pochon de rangement fourni par certaines marques ou
                    bien fournir une matière rapporchante.
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/cloison.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/cloison-2.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/cloison-3.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='patch'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">Patch / Ecusson</h2>
                  <br/><br/><br/>
                  <p>
                    Voici quelques exemples de patch que l'on peut vous proposer.
                    <br/><br/>
                    * en fonction des stocks disponibles
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/patch.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/patch-2.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='coudiere'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">COUDIÈRES</h2>
                  <br/><br/><br/>
                  <p>
                    Voici quelques exemples de coudières que l'on peut vous proposer.
                    <br/><br/>
                    * en fonction des stocks disponibles
                  </p>
                </v-col>
                <v-col class="pa-1" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/patch-3.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='reparation-simple'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">TOUT SAVOIR SUR <br/>LA COUTURE SIMPLE</h2>
                  <br/><br/><br/>
                  <p>
                    La couture simple permet de rapprocher les matières par un point main ou une
                    pince.
                    <br/><br/>
                    Le résultat n'est pas invisible mais reste discret et abordable.
                    <br/><br/>
                    Quelques exemples : <br/>
                  </p>
                </v-col>
                <v-col class="pa-1" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/doudoune-cs.png"/>
                </v-col>
                <v-col class="pa-1" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/doudoune-cs-2.png"/>
                </v-col>
              </v-row>
            </slot>
          </div>
        </section>

        <section v-else-if="$props.type=='VESTE/BLOUSON'" class="modal-body">
          <div class="wrapper">
            <slot v-if="$props.constat=='couture-decousue'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">ALORS DECOUSU OU DECHIRE ?</h2>
                  <br/><br/><br/>
                  <p>
                    <b>Couture décousue</b><br/>
                    <br/>
                    Lorsque la couture est décousue, la matière est en bon état. Vous êtes donc au
                    bon endroit !
                  </p>
                </v-col>
                <v-col class="pa-1" cols="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/manteau-decousu.png"/>
                </v-col>
                <v-col class="pa-1" cols="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/manteau-decousu-2.png"/>
                </v-col>
                <v-col class="pa-1" cols="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/manteau-decousu-3.png"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <br/><br/><br/>
                  <p>
                    <b>Couture déchirée, trouée, accroc ?</b><br/>
                    <br/>
                    Lorsque la matière est déchirée, il s'agit bien d'un accroc, d'un trou ou d'une
                    couture déchirée.
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/manteau-dechire.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/manteau-dechire-2.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/manteau-dechire-3.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='empiecement'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">RÉPARATION D'UNE CLOISON</h2>
                  <br/><br/><br/>
                  <p>
                    La réparation d'un trou par une cloison consiste à recouvrir le boudin de la
                    doudoune abimé par une matière similaire ou identique.
                    <br/><br/>
                    Nous pouvons utiliser le pochon de rangement fourni par certaines marques ou
                    bien fournir une matière rapporchante.
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/cloison.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/cloison-2.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/cloison-3.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='patch'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">Patch / Ecusson</h2>
                  <br/><br/><br/>
                  <p>
                    Voici quelques exemples de patch que l'on peut vous proposer.
                    <br/><br/>
                    * en fonction des stocks disponibles
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/patch.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/patch-2.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='coudiere'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">COUDIÈRES</h2>
                  <br/><br/><br/>
                  <p>
                    Voici quelques exemples de coudières que l'on peut vous proposer.
                    <br/><br/>
                    * en fonction des stocks disponibles
                  </p>
                </v-col>
                <v-col class="pa-1" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/patch-3.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='reparation-simple'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">TOUT SAVOIR SUR <br/>LA COUTURE SIMPLE</h2>
                  <br/><br/><br/>
                  <p>
                    La couture simple permet de rapprocher les matières par un point main ou une
                    pince.
                    <br/><br/>
                    Le résultat n'est pas invisible mais reste discret et abordable.
                    <br/><br/>
                    Quelques exemples : <br/>
                  </p>
                </v-col>
                <v-col class="pa-1" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/doudoune-cs.png"/>
                </v-col>
                <v-col class="pa-1" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/doudoune-cs-2.png"/>
                </v-col>
              </v-row>
            </slot>
          </div>
        </section>

        <section v-else-if="$props.type=='PULL/GILET' && $props.constat!='cart'" class="modal-body">
          <slot v-if="$props.constat=='reparation-simple'" name="body">
            <v-row>
              <v-col cols="12">
                <h2 class="hmodal">TOUT SAVOIR SUR LA <br/>

                  COUTURE SIMPLE</h2>
                <br/><br/><br/>
                <p>
                  La couture simple permet de rapprocher les matières par un point main.
                  <br/><br/>
                  Le résultat n'est pas invisible mais reste discret et abordable.
                  <br/><br/>
                  Quelques exemples : <br/>
                </p>
              </v-col>
              <v-col class="pa-1 text-center" cols="12" sm="4">
                <img src="https://www.lesreparables.fr/cv2/clothetype/modal/cs-pg.png"/>
              </v-col>
              <v-col class="pa-1 text-center" cols="12" sm="4">
                <img src="https://www.lesreparables.fr/cv2/clothetype/modal/cs-pg-2.png"/>
              </v-col>
              <v-col class="pa-1 text-center" cols="12" sm="4">
                &nbsp;
              </v-col>
            </v-row>
          </slot>
          <slot v-if="$props.constat=='coudiere'" name="body">
            <v-row>
              <v-col cols="12">
                <h2 class="hmodal">COUDIÈRES / GENOUILLÈRES</h2>
                <br/><br/><br/>
                <p>
                  Voici quelques exemples de coudières que l'on peut vous proposer.
                  <br/><br/>
                  * en fonction des stocks disponibles
                </p>
              </v-col>
              <v-col class="pa-1" cols="12" sm="4">
                <img src="https://www.lesreparables.fr/cv2/clothetype/modal/patch-3.png"/>
              </v-col>
            </v-row>
          </slot>
          <slot v-if="$props.constat=='couture-decousue'" name="body">
            <v-row>
              <v-col cols="12">
                <h2 class="hmodal">ALORS DECOUSU OU DECHIRE ?</h2>
                <br/><br/><br/>
                <p>
                  <b>Couture décousue</b><br/>
                  <br/>
                  Lorsque la couture est décousue, la matière est en bon état. Vous êtes donc au bon
                  endroit !
                </p>
              </v-col>
              <v-col class="pa-1 text-center" cols="12" sm="4">
                <img src="https://www.lesreparables.fr/cv2/clothetype/modal/cd-pg.png"/>
              </v-col>
              <v-col class="pa-1 text-center" cols="12" sm="4">
                <img src="https://www.lesreparables.fr/cv2/clothetype/modal/cd-pg-2.png"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <br/><br/><br/>
                <p>
                  <b>Couture déchirée, trouée, accroc ?</b><br/>
                  <br/>
                  Lorsque la matière est déchirée, il s'agit bien d'un accroc, d'un trou ou d'une
                  couture déchirée.
                </p>
              </v-col>
              <v-col class="pa-1 text-center" cols="12" sm="4">
                <img src="https://www.lesreparables.fr/cv2/clothetype/modal/ct-pg.png"/>
              </v-col>
              <v-col class="pa-1 text-center" cols="12" sm="4">
                <img src="https://www.lesreparables.fr/cv2/clothetype/modal/ct-pg-2.png"/>
              </v-col>
            </v-row>
          </slot>
        </section>

        <section v-else-if="$props.type=='SWEAT/HOODIE'" class="modal-body">
          <div class="wrapper">
            <slot v-if="$props.constat=='couture-decousue'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">ALORS DECOUSU OU DECHIRE ?</h2>
                  <br/><br/><br/>
                  <p>
                    <b>Couture décousue</b><br/>
                    <br/>
                    Lorsque la couture est décousue, la matière est en bon état. Vous êtes donc au
                    bon endroit !
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/cd-pg.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/cd-pg-2.png"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <br/><br/><br/>
                  <p>
                    <b>Couture déchirée, trouée, accroc ?</b><br/>
                    <br/>
                    Lorsque la matière est déchirée, il s'agit bien d'un accroc, d'un trou ou d'une
                    couture déchirée.
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/ct-pg.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/ct-pg-2.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='empiecement'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">EMPIÈCEMENT</h2>
                  <br/><br/><br/>
                  <p>
                    Après avoir sélectionné ensemble la matière première, nous réalisons un
                    empiècement pour réparer votre vêtement.
                    <br/><br/>
                    Le but, rendre la réparation le plus invisible possible en respectant le style
                    de votre vêtement.
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/empiecement.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/empiecement-2.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/empiecement-3.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='patch'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">Patch / Écusson</h2>
                  <br/><br/><br/>
                  <p>
                    Voici quelques exemples de patch que l'on peut vous proposer.
                    <br/><br/>
                    * en fonction des stocks disponibles
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/patch.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/patch-2.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='coudiere'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">COUDIÈRES / GENOUILLÈRES</h2>
                  <br/><br/><br/>
                  <p>
                    Voici quelques exemples de coudières que l'on peut vous proposer.
                    <br/><br/>
                    * en fonction des stocks disponibles
                  </p>
                </v-col>
                <v-col class="pa-1" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/patch-3.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='reparation-simple'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">TOUT SAVOIR SUR <br/>LA COUTURE SIMPLE</h2>
                  <br/><br/><br/>
                  <p>
                    La couture simple permet de rapprocher les matières par un point main ou une
                    pince.
                    <br/><br/>
                    Le résultat n'est pas invisible mais reste discret et abordable.
                    <br/><br/>
                    Quelques exemples : <br/>
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img
                    src="https://www.lesreparables.fr/cv2/clothetype/modal/couture-simple-haut.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img
                    src="https://www.lesreparables.fr/cv2/clothetype/modal/couture-simple-haut-2.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img
                    src="https://www.lesreparables.fr/cv2/clothetype/modal/couture-simple-haut-3.png"/>
                </v-col>
              </v-row>
            </slot>
          </div>
        </section>

        <section v-else-if="$props.type=='SALOPETTE/COMBI/ROBE'" class="modal-body">
          <div class="wrapper">
            <slot v-if="$props.constat=='couture-decousue'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">ALORS DECOUSU OU DECHIRE ?</h2>
                  <br/><br/><br/>
                  <p>
                    <b>Couture décousue</b><br/>
                    <br/>
                    Lorsque la couture est décousue, la matière est en bon état. Vous êtes donc au
                    bon endroit !
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/cd-pg.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/cd-pg-2.png"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <br/><br/><br/>
                  <p>
                    <b>Couture déchirée, trouée, accroc ?</b><br/>
                    <br/>
                    Lorsque la matière est déchirée, il s'agit bien d'un accroc, d'un trou ou d'une
                    couture déchirée.
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/ct-pg.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/ct-pg-2.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='empiecement'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">EMPIÈCEMENT</h2>
                  <br/><br/><br/>
                  <p>
                    Après avoir sélectionné ensemble la matière première, nous réalisons un
                    empiècement pour réparer votre vêtement.
                    <br/><br/>
                    Le but, rendre la réparation le plus invisible possible en respectant le style
                    de votre vêtement.
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/empiecement.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/empiecement-2.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/empiecement-3.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='patch'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">Patch / Écusson</h2>
                  <br/><br/><br/>
                  <p>
                    Voici quelques exemples de patch que l'on peut vous proposer.
                    <br/><br/>
                    * en fonction des stocks disponibles
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/patch.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/patch-2.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='genouillere'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">COUDIÈRES / GENOUILLÈRES</h2>
                  <br/><br/><br/>
                  <p>
                    Voici quelques exemples de coudières que l'on peut vous proposer.
                    <br/><br/>
                    * en fonction des stocks disponibles
                  </p>
                </v-col>
                <v-col class="pa-1" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/patch-3.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='reparation-simple'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">TOUT SAVOIR SUR <br/>LA COUTURE SIMPLE</h2>
                  <br/><br/><br/>
                  <p>
                    La couture simple permet de rapprocher les matières par un point main ou une
                    pince.
                    <br/><br/>
                    Le résultat n'est pas invisible mais reste discret et abordable.
                    <br/><br/>
                    Quelques exemples : <br/>
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img
                    src="https://www.lesreparables.fr/cv2/clothetype/modal/couture-simple-haut.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img
                    src="https://www.lesreparables.fr/cv2/clothetype/modal/couture-simple-haut-2.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img
                    src="https://www.lesreparables.fr/cv2/clothetype/modal/couture-simple-haut-3.png"/>
                </v-col>
              </v-row>
            </slot>
          </div>
        </section>

        <section v-else-if="$props.type=='ACCESSOIRES DIVERS'" class="modal-body">
          <div class="wrapper">
            <slot v-if="$props.constat=='couture-decousue'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">ALORS DECOUSU OU DECHIRE ?</h2>
                  <br/><br/><br/>
                  <p>
                    <b>Couture décousue</b><br/>
                    <br/>
                    Lorsque la couture est décousue, la matière est en bon état. Vous êtes donc au
                    bon endroit !
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/cd-pg.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/cd-pg-2.png"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <br/><br/><br/>
                  <p>
                    <b>Couture déchirée, trouée, accroc ?</b><br/>
                    <br/>
                    Lorsque la matière est déchirée, il s'agit bien d'un accroc, d'un trou ou d'une
                    couture déchirée.
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/ct-pg.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/ct-pg-2.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='empiecement'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">EMPIÈCEMENT</h2>
                  <br/><br/><br/>
                  <p>
                    Après avoir sélectionné ensemble la matière première, nous réalisons un
                    empiècement pour réparer votre vêtement.
                    <br/><br/>
                    Le but, rendre la réparation le plus invisible possible en respectant le style
                    de votre vêtement.
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/empiecement.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/empiecement-2.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/empiecement-3.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='patch'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">Patch / Écusson</h2>
                  <br/><br/><br/>
                  <p>
                    Voici quelques exemples de patch que l'on peut vous proposer.
                    <br/><br/>
                    * en fonction des stocks disponibles
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/patch.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/patch-2.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='genouillere'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">COUDIÈRES / GENOUILLÈRES</h2>
                  <br/><br/><br/>
                  <p>
                    Voici quelques exemples de coudières que l'on peut vous proposer.
                    <br/><br/>
                    * en fonction des stocks disponibles
                  </p>
                </v-col>
                <v-col class="pa-1" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/patch-3.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='zip'" name="body">
              <v-row>
                <v-col cols="6">
                  <p>
                    Si la maille du zip est encore en bon état et que seul le curseur ne fonctionne
                    plus, il est possible de remplacer uniquement celui-ci.
                  </p>
                </v-col>
                <v-col class="pa-1" cols="6">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/curseur-zip.png"/>
                </v-col>
                <v-col cols="12">
                  <div><br/><br/>Exemple de cas où l'on peut changer que le curseur (Maille intacte)
                  </div>
                </v-col>
                <v-col class="pa-1" cols="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/maille-ok.png"/>
                </v-col>
                <v-col class="pa-1" cols="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/maille-ok-2.png"/>
                </v-col>
                <v-col class="pa-1" cols="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/maille-ok-3.png"/>
                </v-col>
                <v-col cols="12">
                  <div>Exemple de cas où il faut tout changer (Maille abîmée)</div>
                </v-col>
                <v-col class="pa-1" cols="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/maille-nok.png"/>
                </v-col>
                <v-col class="pa-1" cols="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/maille-nok-2.png"/>
                </v-col>
                <v-col class="pa-1" cols="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/maille-nok-3.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='reparation-simple'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">TOUT SAVOIR SUR <br/>LA COUTURE SIMPLE</h2>
                  <br/><br/><br/>
                  <p>
                    La couture simple permet de rapprocher les matières par un point main ou une
                    pince.
                    <br/><br/>
                    Le résultat n'est pas invisible mais reste discret et abordable.
                    <br/><br/>
                    Quelques exemples : <br/>
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img
                    src="https://www.lesreparables.fr/cv2/clothetype/modal/couture-simple-haut.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img
                    src="https://www.lesreparables.fr/cv2/clothetype/modal/couture-simple-haut-2.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img
                    src="https://www.lesreparables.fr/cv2/clothetype/modal/couture-simple-haut-3.png"/>
                </v-col>
              </v-row>
            </slot>
          </div>
        </section>

        <section v-else-if="$props.type=='CHEMISE'" class="modal-body">
          <div class="wrapper">
            <slot v-if="$props.constat=='couture-decousue'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">ALORS DECOUSU OU DECHIRE ?</h2>
                  <br/><br/><br/>
                  <p>
                    <b>Couture décousue</b><br/>
                    <br/>
                    Lorsque la couture est décousue, la matière est en bon état. Vous êtes donc au
                    bon endroit !
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/cd-pg.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/cd-pg-2.png"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <br/><br/><br/>
                  <p>
                    <b>Couture déchirée, trouée, accroc ?</b><br/>
                    <br/>
                    Lorsque la matière est déchirée, il s'agit bien d'un accroc, d'un trou ou d'une
                    couture déchirée.
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/ct-pg.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/ct-pg-2.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='empiecement'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">EMPIÈCEMENT</h2>
                  <br/><br/><br/>
                  <p>
                    Après avoir sélectionné ensemble la matière première, nous réalisons un
                    empiècement pour réparer votre vêtement.
                    <br/><br/>
                    Le but, rendre la réparation le plus invisible possible en respectant le style
                    de votre vêtement.
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/empiecement.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/empiecement-2.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/empiecement-3.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='patch'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">Patch / Écusson</h2>
                  <br/><br/><br/>
                  <p>
                    Voici quelques exemples de patch que l'on peut vous proposer.
                    <br/><br/>
                    * en fonction des stocks disponibles
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/patch.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/patch-2.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='coudiere'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">COUDIÈRES / GENOUILLÈRES</h2>
                  <br/><br/><br/>
                  <p>
                    Voici quelques exemples de coudières que l'on peut vous proposer.
                    <br/><br/>
                    * en fonction des stocks disponibles
                  </p>
                </v-col>
                <v-col class="pa-1" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/patch-3.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='zip'" name="body">
              <v-row>
                <v-col cols="6">
                  <p>
                    Si la maille du zip est encore en bon état et que seul le curseur ne fonctionne
                    plus, il est possible de remplacer uniquement celui-ci.
                  </p>
                </v-col>
                <v-col class="pa-1" cols="6">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/curseur-zip.png"/>
                </v-col>
                <v-col cols="12">
                  <div><br/><br/>Exemple de cas où l'on peut changer que le curseur (Maille intacte)
                  </div>
                </v-col>
                <v-col class="pa-1" cols="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/maille-ok.png"/>
                </v-col>
                <v-col class="pa-1" cols="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/maille-ok-2.png"/>
                </v-col>
                <v-col class="pa-1" cols="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/maille-ok-3.png"/>
                </v-col>
                <v-col cols="12">
                  <div>Exemple de cas où il faut tout changer (Maille abîmée)</div>
                </v-col>
                <v-col class="pa-1" cols="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/maille-nok.png"/>
                </v-col>
                <v-col class="pa-1" cols="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/maille-nok-2.png"/>
                </v-col>
                <v-col class="pa-1" cols="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/maille-nok-3.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='reparation-simple'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">TOUT SAVOIR SUR <br/>LA COUTURE SIMPLE</h2>
                  <br/><br/><br/>
                  <p>
                    La couture simple permet de rapprocher les matières par un point main ou une
                    pince.
                    <br/><br/>
                    Le résultat n'est pas invisible mais reste discret et abordable.
                    <br/><br/>
                    Quelques exemples : <br/>
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img
                    src="https://www.lesreparables.fr/cv2/clothetype/modal/couture-simple-haut.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img
                    src="https://www.lesreparables.fr/cv2/clothetype/modal/couture-simple-haut-2.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img
                    src="https://www.lesreparables.fr/cv2/clothetype/modal/couture-simple-haut-3.png"/>
                </v-col>
              </v-row>
            </slot>
          </div>
        </section>

        <section v-else-if="$props.type=='POLO/T.SHIRT/BLOUSE'" class="modal-body">
          <div class="wrapper">
            <slot v-if="$props.constat=='couture-decousue'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">ALORS DECOUSU OU DECHIRE ?</h2>
                  <br/><br/><br/>
                  <p>
                    <b>Couture décousue</b><br/>
                    <br/>
                    Lorsque la couture est décousue, la matière est en bon état. Vous êtes donc au
                    bon endroit !
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/cd-pg.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/cd-pg-2.png"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <br/><br/><br/>
                  <p>
                    <b>Couture déchirée, trouée, accroc ?</b><br/>
                    <br/>
                    Lorsque la matière est déchirée, il s'agit bien d'un accroc, d'un trou ou d'une
                    couture déchirée.
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/ct-pg.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/ct-pg-2.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='empiecement'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">EMPIÈCEMENT</h2>
                  <br/><br/><br/>
                  <p>
                    Après avoir sélectionné ensemble la matière première, nous réalisons un
                    empiècement pour réparer votre vêtement.
                    <br/><br/>
                    Le but, rendre la réparation le plus invisible possible en respectant le style
                    de votre vêtement.
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/empiecement.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/empiecement-2.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/empiecement-3.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='patch'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">Patch / Écusson</h2>
                  <br/><br/><br/>
                  <p>
                    Voici quelques exemples de patch que l'on peut vous proposer.
                    <br/><br/>
                    * en fonction des stocks disponibles
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/patch.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/patch-2.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='coudiere'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">COUDIÈRES / GENOUILLÈRES</h2>
                  <br/><br/><br/>
                  <p>
                    Voici quelques exemples de coudières que l'on peut vous proposer.
                    <br/><br/>
                    * en fonction des stocks disponibles
                  </p>
                </v-col>
                <v-col class="pa-1" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/patch-3.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='reparation-simple'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">TOUT SAVOIR SUR <br/>LA COUTURE SIMPLE</h2>
                  <br/><br/><br/>
                  <p>
                    La couture simple permet de rapprocher les matières par un point main ou une
                    pince.
                    <br/><br/>
                    Le résultat n'est pas invisible mais reste discret et abordable.
                    <br/><br/>
                    Quelques exemples : <br/>
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img
                    src="https://www.lesreparables.fr/cv2/clothetype/modal/couture-simple-haut.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img
                    src="https://www.lesreparables.fr/cv2/clothetype/modal/couture-simple-haut-2.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img
                    src="https://www.lesreparables.fr/cv2/clothetype/modal/couture-simple-haut-3.png"/>
                </v-col>
              </v-row>
            </slot>
          </div>
        </section>
        <section v-else-if="$props.type=='JEAN'" class="modal-body">
          <div class="wrapper">
            <slot v-if="$props.constat=='couture-decousue'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">ALORS DECOUSU OU DECHIRE ?</h2>
                  <br/><br/><br/>
                  <p>
                    <b>Couture décousue</b><br/>
                    <br/>
                    Lorsque la couture est décousue, la matière est en bon état. Vous êtes donc au
                    bon endroit !
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/cd-pg.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/cd-pg-2.png"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <br/><br/><br/>
                  <p>
                    <b>Couture déchirée, trouée, accroc ?</b><br/>
                    <br/>
                    Lorsque la matière est déchirée, il s'agit bien d'un accroc, d'un trou ou d'une
                    couture déchirée.
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/ct-pg.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/ct-pg-2.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='empiecement'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">EMPIÈCEMENT</h2>
                  <br/><br/><br/>
                  <p>
                    Après avoir sélectionné ensemble la matière première, nous réalisons un
                    empiècement pour réparer votre vêtement.
                    <br/><br/>
                    Le but, rendre la réparation le plus invisible possible en respectant le style
                    de votre vêtement.
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/empiecement.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/empiecement-2.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/empiecement-3.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='patch'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">Patch / Écusson</h2>
                  <br/><br/><br/>
                  <p>
                    Voici quelques exemples de patch que l'on peut vous proposer.
                    <br/><br/>
                    * en fonction des stocks disponibles
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/patch.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/patch-2.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='genouillere'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">COUDIÈRES / GENOUILLÈRES</h2>
                  <br/><br/><br/>
                  <p>
                    Voici quelques exemples de coudières que l'on peut vous proposer.
                    <br/><br/>
                    * en fonction des stocks disponibles
                  </p>
                </v-col>
                <v-col class="pa-1" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/patch-3.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='zip'" name="body">
              <v-row>
                <v-col cols="6">
                  <p>
                    Si la maille du zip est encore en bon état et que seul le curseur ne fonctionne
                    plus, il est possible de remplacer uniquement celui-ci.
                  </p>
                </v-col>
                <v-col class="pa-1" cols="6">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/curseur-zip.png"/>
                </v-col>
                <v-col cols="12">
                  <div><br/><br/>Exemple de cas où l'on peut changer que le curseur (Maille intacte)
                  </div>
                </v-col>
                <v-col class="pa-1" cols="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/maille-ok.png"/>
                </v-col>
                <v-col class="pa-1" cols="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/maille-ok-2.png"/>
                </v-col>
                <v-col class="pa-1" cols="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/maille-ok-3.png"/>
                </v-col>
                <v-col cols="12">
                  <div>Exemple de cas où il faut tout changer (Maille abîmée)</div>
                </v-col>
                <v-col class="pa-1" cols="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/maille-nok.png"/>
                </v-col>
                <v-col class="pa-1" cols="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/maille-nok-2.png"/>
                </v-col>
                <v-col class="pa-1" cols="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/maille-nok-3.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='reparation-simple'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">TOUT SAVOIR SUR <br/>LA COUTURE SIMPLE</h2>
                  <br/><br/><br/>
                  <p>
                    La couture simple permet de rapprocher les matières par un point main ou une
                    pince.
                    <br/><br/>
                    Le résultat n'est pas invisible mais reste discret et abordable.
                    <br/><br/>
                    Quelques exemples : <br/>
                  </p>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img
                    src="https://www.lesreparables.fr/cv2/clothetype/modal/couture-simple-haut.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img
                    src="https://www.lesreparables.fr/cv2/clothetype/modal/couture-simple-haut-2.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img
                    src="https://www.lesreparables.fr/cv2/clothetype/modal/couture-simple-haut-3.png"/>
                </v-col>
              </v-row>
            </slot>
            <slot v-if="$props.constat=='reparation-simple-jean'" name="body">
              <v-row>
                <v-col cols="12">
                  <h2 class="hmodal">AVANT / APRÈS</h2>
                  <br/><br/><br/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/jean-st.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/jean-st-2.png"/>
                </v-col>
                <v-col class="pa-1 text-center" cols="12" sm="4">
                  <img src="https://www.lesreparables.fr/cv2/clothetype/modal/jean-st-3.png"/>
                </v-col>
              </v-row>
            </slot>
          </div>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
import {mapMutations, mapState} from 'vuex'
import {formatPrice} from "@/helper/PriceHelper";
import ZipModalContent from "@/components/modal/ZipModalContent.vue";
import ZipEtancheModalContent from "@/components/modal/ZipEtancheModalContent.vue";
import InsuranceModalContent from "@/components/modal/InsuranceModalContent.vue";
import EmpiecementDoudouneModalContent from "@/components/modal/EmpiecementDoudouneModalContent.vue";
import ReparationDoudouneModalContent from "@/components/modal/ReparationDoudouneModalContent.vue";

export default {
  name: 'Modal',
  components: {
    EmpiecementDoudouneModalContent,
    InsuranceModalContent,
    ZipEtancheModalContent,
    ReparationDoudouneModalContent,
    ZipModalContent
  },
  props: {
    type: String,
    constat: String
  },
  computed: {
    ...mapState('cart-app', ['cards', 'products']),
    ...mapState('flux-app', ['flux']),
    title() {
      if (this.$props.constat === 'zip') {
        return 'REMPLACER UN CURSEUR'
      }

      if (this.$props.constat === 'cart') {
        return 'MON PANIER'
      }

      if (this.$props.constat === 'zip-etanche') {
        return 'QU\'EST-CE QU\'UN ZIP ÉTANCHE'
      }

      if (this.$props.constat === 'empiecement-doudoune') {
        return 'EMPIÈCEMENT / CLOISON'
      }

      if (this.$props.constat === 'reparation-doudoune') {
        return 'RÉPARATION SIMPLE'
      }

      if (this.$props.type === 'insurance') {
        return 'PROTECTION TRANSPORT'
      }

      return null
    },
    useComponent() {
      if (this.$props.type === 'cart') {
        return false
      }

      if (this.$props.type === 'insurance') {
        return true
      }

      return ['zip', 'zip-etanche', 'empiecement-doudoune', 'reparation-doudoune'].includes(this.$props.constat)
    },
    componentName() {
      return `${this.$props.constat ?? this.$props.type}-modal-content`
    }
  },
  methods: {
    formatPrice,
    ...mapMutations('flux-app', ['setcptProduct', 'setcptReparation']),
    ...mapMutations('cart-app', ['setOrderId']),
    gotoRepair(index, indexR) {
      this.$emit('edit', [index, indexR])
      this.$emit('evenement', 'scroll4')
      this.close()
    },
    deleteProduct(index, indexR) {
      this.$emit('delete', [index, indexR])
    },
    close() {
      this.setOrderId(null)
      this.$emit('close');
    },
    other() {
      this.setcptProduct(this.products.length + 1)
      // this.setcptReparation(0)
      this.$emit('evenement', 'scroll1')
      this.close()
    }
  },
};
</script>

<style>
#cart_tab {
  min-width: 100%;
}

#cart_tab thead {
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  padding: 5px;
}

#cart_tab td {
  padding: 10px
}

.recap {
  background-color: #eeeeee;
  padding: 10px;
}

@media (min-width: 768px) {
  .recap {
    min-height: calc(100vh - 130px);
    height: 100%;
  }
}

#cart_tab td.soustotal {
  border-bottom: 2px solid #000;
  padding-top: 40px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
  transform: translate(5000px);
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: all .7s ease;
}

.mymodal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.mymodal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  z-index: 1200;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
  align-items: center !important;
}

.modal-header {
  color: #4AAE9B;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 10;
}

.modal-header h2 {
  margin: 0 !important;
  color: black;
}

.modal-footer {
  flex-direction: column;
  justify-content: flex-end;
}

.modal-body {
  margin: 15px;
}

.modal-body img {
  max-width: 300px;
}

.btn-close {
  position: absolute;
  top: -20px;
  right: 0;
  border: none;
  font-size: 40px;
  padding: 10px;
  cursor: pointer;
  font-weight: normal;
  color: #000 !important;
  background: transparent;
}

.btn-green {
  color: white;
  background: #4AAE9B;
  border: 1px solid #4AAE9B;
  border-radius: 2px;
}

img {
  width: 100%;
}

h2.hmodal {
  font-size: 1.3rem !important;
}

.cproduct {
  padding: 10px;
}

#bonus {
  max-width: 70px;
  object-fit: contain;
}
</style>
