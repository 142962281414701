import Vue from 'vue'
import Vuex from 'vuex'

// Example Apps
import CartModule from './modules/cart/store'
import EnvironmentModule from './modules/environment/store'
import InsuranceModule from './modules/insurance/store'
import FluxModule from './modules/flux/store'
import UserModule from './modules/user/store'

Vue.use(Vuex)

const getKey = (state) => state['environment-app']?.environment?.origin ?? 'CALCULATEUR'

/**
 * Main Vuex Store
 */
const store = new Vuex.Store({
    modules: {
        'cart-app': CartModule,
        'environment-app': EnvironmentModule,
        'insurance-app': InsuranceModule,
        'flux-app': FluxModule,
        'user-app': UserModule,
    },
    mutations: {
        initialiseStore(state) {
            const key = getKey(state)
            const storedData = localStorage.getItem(key)

            if (storedData) {
                try {
                    const jsonData = JSON.parse(storedData)
                    this.replaceState({...state, ...jsonData})
                } catch {
                    localStorage.removeItem(key)
                }
            }

            store.commit('environment-app/setLoaded')
        }
    }
})

store.subscribe((mutation, state) => {
    if (mutation.type === 'environment-app/setReady') {
        store.commit('cart-app/resetCart')
        store.commit('initialiseStore')

        return
    }

    if (!state['environment-app'].environment.isLoaded) {
        return
    }

    localStorage.setItem(getKey(state), JSON.stringify({
        'cart-app': state['cart-app'],
        'user-app': state['user-app'],
    }))
})

export default store
